<!--
 * @Descripttion: 选刊详情
 * @Author: zyr
 * @Date: 2021-05-29 22:54:17
 * @LastEditors: zyr
 * @LastEditTime: 2021-09-18 00:32:35
-->
<template>
  <div class="detail-con">
    <!-- 问题 -->
    <div class="common-reply">
      <h6 class="reply-tlt">{{info.title}}</h6>
      <div class="reply-que">
        <img src="../../../assets/imgs/descri.png" alt="" class="descri-img">
        <div>
          <!-- 类型 -->
          <div class="detail-type">类型: <span class="type-txt">{{info.typename}}</span></div>
          <div class="reply-desc">{{info.content}}</div>
        </div>
      </div>
      <p class="reply-date">{{info.date | getDate}}</p>
    </div>
    <!-- 回答 -->
    <div v-if="info.list.length" class="ans-list">
      <div v-for="v in info.list" :key="v.id">
        <div class="common-reply">
          <!-- 用户信息 -->
          <div class="user-info hack-border-bottom">
            <div class="user-intro">
              <h5 class="user-name">{{v.userinfo.name}}</h5>
              <p class="user-title">{{v.userinfo.duties}}</p>
            </div>
          </div>
          <!-- 回复内容 -->
          <div class="user-mail reply-mail">{{v.content}}<a v-if="v.fileurl" :href="v.fileurl" class="mail-a">{{v.filename}}</a></div>
          <!-- 时间 -->
          <div class="user-mail mail-date reply-date">{{v.date}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { publishReply } = API
export default {
  name: 'LetterDetail',
  data () {
    return {
      info: {
        type: 0,
        title: '',
        content: '',
        date: '',
        list: []
      }
    }
  },
  methods: {
    getDetail (id) {
      this.$axios({
        url: `${publishReply}${id}`,
        method: 'get'
      }).then(({ code, msg, model }) => {
        if (code === 0) {
          this.info = model
        }
      })
    }
  },
  filters: {
    getDate (date) {
      return date.split(' ')[0].slice(5)
    }
  },
  beforeRouteEnter (to, from, next) {
    const { query: { id } } = to
    next(vm => {
      vm.getDetail(id)
    })
  }
}
</script>
<style lang='less'>
@import '../../../assets/less/flex.less';
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
}
.van-button__text{
  color: #8993A8;
  font-size: .px2rem(32px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../../assets/less/flex.less';
@import '../../../assets/less/common.less';
.detail-con{
  padding: .px2rem(32px)[@rem] 0;
  margin: 0 .px2rem(32px)[@rem];
  box-sizing: border-box;
  color: #8993A8;
}
.reply-que{
  display: flex;
}
.descri-img{
  width: .px2rem(72px)[@rem];
  height: .px2rem(72px)[@rem];
  flex-shrink: 0;
  margin-right: .px2rem(20px)[@rem];
}
.type-txt{
  padding-left: .px2rem(16px)[@rem];
}
.detail-type{
  font-size: .px2rem(28px)[@rem];
  color: #5460FE;
  // margin-bottom: .px2rem(20px)[@rem];
}
.common-reply{
  padding: .px2rem(32px)[@rem];
  box-sizing: border-box;
  border-radius: .px2rem(20px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
  background: #F6F7FB;
}
.reply-tlt{
  color: #101112;
  font-size: .px2rem(32px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
}
.reply-desc{
  font-size: .px2rem(28px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
}
.reply-date{
  font-size: .px2rem(28px)[@rem];
  text-align: right;
}
.ans-img{
  margin-left: .px2rem(56px)[@rem];
  margin-right: 0;
}
.reply-mail{
  margin-top: .px2rem(20px)[@rem];
}
.mail-date{
  margin-top: .px2rem(32px)[@rem];
}
.user-flex{
  justify-content: flex-end;
}
</style>
